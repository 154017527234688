import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { MinistriesCategoryCard } from '../../cards';
const MinistrieCategories = () => {


  const controls = useAnimation();
  const { ref, inView } = useInView();

  React.useEffect(() => {
      if (inView) {
        controls.start('visible');
      } else {
        controls.start('hidden');
      }
    }, [controls, inView]);

    const variants = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 50 },
      }
  return (
    <div className='py-10'>
        <div className='w-11/12 md:w-7/12 m-auto'>
            <motion.h4 
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ duration: 1.2, delay: 0.2 }}
            className='text-lg md:text-xl font-xbold text-darkBlue capitalize leading-9 md:leading-10 my-5 text-center'>
                our ministries
            </motion.h4>
            <motion.p 
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ duration: 1.2, delay: 0.4 }}
            className=' text-center my-10 text-sm font-primary'>
              Within the walls of a church, a multitude of ministries thrive, 
              serving diverse needs. From outreach and discipleship to worship and prayer, 
              these ministries foster spiritual growth, unity, and a shared purpose. 
              Each one plays a vital role in nurturing 
              and equipping believers, creating a vibrant and thriving community of faith.
            </motion.p>
        </div>
        <div>
          <MinistriesCategoryCard />
        </div>
    </div>
  )
}

export default MinistrieCategories