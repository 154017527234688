import React, { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaCheckCircle } from "react-icons/fa";
import { useMobileNavigationContext } from '../contexts/mobileNavigationContext';
import { DesktopNavigation, MobileNavigation, MobileNavigationHeader } from '../components/header';
import { ContactSection } from '../components/sections/contact';
import { PageLabel } from '../components/page-label';
import { ImageBackground } from '../components/bgs';
import { GoogleMapLocations } from '../components/google-maps';
import { FAQS } from '../components/faq';
import congregation from '../assets/img/congregation.jpg';
import libertyChapelIct from '../assets/img/liberty-chapel-sda.jpg';
import questionMark from '../assets/img/question-mark-sign.jpeg';

const Contact = () => {
  const { showMobileNavItems, handleShowMobileNavItems } = useMobileNavigationContext();

  const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }
      
    const listItems = [
        'Love and Joy',
        'Peace and Patience',
        'Kindness and Goodness',
        'Faithfulness and Gentleness'
    ]
  return (
    <div className=''>
       <div className='w-full'>
          <DesktopNavigation />
      </div>
     <MobileNavigationHeader 
          handleToggleShowNavitems={handleShowMobileNavItems}
      />
      <PageLabel
        image={congregation}
        label='Contact Us'
        subLabel='Liberty Chapel Seventh-Day Adventist Church'
      />

      <ImageBackground>
              <div className='md:h-1/2 md:w-10/12 md:flex'>
                <div className='h-1/2 md:w-1/2 px-10 md:px-0'>
                  <div className='h-full md:w-9/12 m-auto'>
                      <h2
                       
                      className='text-lg text-darkBlue  font-xbold leading-[2.6rem] md:leading-[4rem]'>
                          Getting Started
                      </h2>
                      <p 
                      className='py-3 text-xsm my-3'>
                      If you have any questions, need guidance or would like to get involved, 
                      please do not hesitate to reach out to us. Our Church office is open during weekdays,
                      and our staff and volunteers are more than happy to assist you.
                      </p>
                      <p 
                      className='py-3 text-xsm my-3'>
                      We also invite you to join us for our upcoming events and gatherings. It's a great way to meet 
                      fellow church members, build relationships, and deepen your connection with God.
                      </p>
                      <ul>
                          {
                              listItems.map((item, index)=>(
                                  <motion.li key={index}
                                  ref={ref}
                                  initial="hidden"
                                  animate={controls}
                                  variants={variants}
                                  transition={{ duration: 0.8, delay: index }}
                                  className='flex items-center gap-3 font-normal text-xsm'>
                                      <span><FaCheckCircle className='text-choco'/></span>{item}
                                  </motion.li>
                              ))
                          }
                      </ul>
                  </div>
              </div>
              <div className='h-1/2 md:h-full md:w-1/2'>
                  <img src={libertyChapelIct} alt="" className='h-full object-contain'/>
              </div>
              </div>
      </ImageBackground>
      <ImageBackground>
              <div className='md:h-1/2 md:w-10/12 md:flex'>
                <div className='h-1/2 md:h-full md:w-1/2'>
                    <img src={questionMark} alt="" className='h-full object-contain'/>
                </div>
                <div className='h-1/2 md:w-1/2 px-10 md:px-0'>
                  <div className='h-full md:w-11/12 m-auto'>
                      <motion.h2
                          ref={ref}
                          initial="hidden"
                          animate={controls}
                          variants={variants}
                          transition={{ duration: 1.2, delay: 0.2 }}
                      className='text-lg text-darkBlue  font-xbold leading-[2.6rem] md:leading-[4rem] capitalize'>
                          frequently asked questions
                      </motion.h2>
                      <FAQS />
                  </div>
                </div>
              </div>
      </ImageBackground>
      <GoogleMapLocations />
      <ContactSection />
      {
          showMobileNavItems && (
              <div className='md:hidden fixed top-0 w-full h-full left-0 z-[999]'>
              <MobileNavigation
                  showNavItems={showMobileNavItems}
                  handleToggleShowNavitems={handleShowMobileNavItems}
              />
              </div>
          )
      }
    </div>
  )
}

export default Contact