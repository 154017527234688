import React from 'react';
import mapImage from '../../../assets/img/map-bw.jpg'

const TopFooter = () => {
  return (
    <div className='h-screen bg-mapImage bg-cover bg-no-repeat bg-center relative'>
        <div className='h-full absolute top-0 left-0 w-full bg-dark/70 flex items-center'>
            <div className='md:w-2/3 text-whiteSmoke m-auto font-primary'>
                <h3 className='capitalize font-bold md:text-lg'>location: ___________________</h3>
                <h4 className=''>2 Wennike Briggs Close, Utako. FCT Abuja</h4>
                <h4 className='font-bold text-yellow'>07061091667</h4>
                <h4 className='font-bold'>Libertychapelsda@gmail.com</h4>
            </div>
        </div>
    </div>
  )
}

export default TopFooter