import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaInstagram } from "react-icons/fa6";
import { FaTwitter, FaYoutube, FaVideo  } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import { motion } from 'framer-motion';
import { containerVariants } from '../../utils/animations/slideToRight';
import LibertChapelHeroImage from '../../assets/img/liberty-profile-2.jpg';
import logo from '../../assets/svg/logo_image.svg'

const LiveStream = ({ handleToggleLiveStreamModal }) => {

  const SocialAccounts = [
    {
        name:'facebook',
        url:'https://m.facebook.com/LibertyChapelOnline/live',
        icon:<FaFacebookF className='text-blue'/>
    },
    {
        name:'youtube',
        url:'https://www.youtube.com/watch?v=1ufWTGWnkfQ',
        icon:<FaYoutube className='text-red-700' />
    },
    {
        name:'Zoom',
        url:'https://us02web.zoom.us/j/81657267250#success',
        icon:<FaVideo  className='text-blue'/>
    },
    {
        name:'instagram',
        url:'https://www.instagram.com/lc_sda/',
        icon:<FaInstagram className='text-yellow' />
    },
    
]

  return (
    <motion.div className='h-screen'
       variants={containerVariants}
       initial="hidden"
       animate="visible"
    >
      <div className='h-full flex items-center justify-center'>
        <div className='w-full h-2/3 md:w-7/12 m-auto flex  bg-[#e3e3e3]/60 mx-3 md:px-0'>
          <div className='hidden md:block w-1/2'>
            <img src={LibertChapelHeroImage} className='h-full w-full object-cover' />
          </div>
          <div className='w-full md:w-1/2 flex flex-col items-center justify-center relative'>
            <img src={logo} className='w-12 h-12 border border-white rounded-full my-5' 
            alt='liberty chapel seventh day adventist church logo' />
            <span onClick={handleToggleLiveStreamModal} className='absolute top-3 right-10 cursor-pointer'><LiaTimesSolid className='text-white' /></span>
          {
            SocialAccounts.map((item, index)=>(
              // <button onClick={()=>handleRedirect(item)} key={index} className='w-3/4 flex items-center 
              // justify-center gap-3 my-1 text-sm font-primary font-bold
              // bg-white min-w-[12rem] py-2 rounded-full hover:bg-yellow/10 hover:text-white duration-300 ease-in-out'>
              //   <span>{item.icon}</span>{item.name}</button>
              <a
                href={item.url}
                key={index}
                // target='_blank'
                rel='noopener noreferrer'
                className='w-3/4 flex items-center 
                justify-center gap-3 my-1 text-sm font-primary font-bold
                bg-white min-w-[12rem] py-2 rounded-full hover:bg-yellow/10 hover:text-white duration-300 ease-in-out'
              >
                <span>{item.icon}</span>
                {item.name}
              </a>
            ))
          }
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default LiveStream