import React, { useState, useEffect } from 'react';
import { Button } from '../button';
import { motion, useAnimation } from 'framer-motion';
import { FaLongArrowAltRight } from "react-icons/fa";
import LibertChapelHeroImage from '../../assets/img/liberty-profile-2.jpg'
import { DesktopNavigation, MobileNavigationHeader, MobileNavigation } from '../header';
import { LiveStream } from '../modals';;



const Hero = () => {
    const [showLiveStreamModal, setShowLiveStreamModal] = useState(false);
    const [showNavItems, setShowNavItems] = useState(false);

    const handleToggleShowNavitems = ()=>{
        setShowNavItems(!showNavItems)
    }
    const handleToggleLiveStreamModal = ()=>{
        setShowLiveStreamModal(!showLiveStreamModal);
    }

    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
    }

    const buttonVariants = {
        hidden: { opacity: 0, x: -70 },
        visible: { opacity: 1, x: 0 },
    };
    const buttonVariantsRight = {
        hidden: { opacity: 0, x: 70 },
        visible: { opacity: 1, x: 0 },
    };

  return (
    <div className='h-screen'>
        <div className='h-full w-full relative'>
            <img src={LibertChapelHeroImage} className='h-full w-full object-cover' />
            <div className='h-full flex absolute w-full top-0'>
                <div className='h-full w-1/2 bg-[#0945AA] opacity-50'>
                    
                </div>
                <div className='h-full w-1/2 bg-dark/40'>

                </div>
            </div>
            <div className='h-full flex flex-col items-cente justify-center absolute w-full top-0  font-primary'>
                <MobileNavigationHeader 
                    handleToggleShowNavitems={handleToggleShowNavitems}
                />
                <div className='md:w-9/12 m-auto text-white'>
                    <h3 className='md:ml-3 font-light font-subPrimary text-sm'>Welcome to Church</h3>
                    <motion.h1 
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.2 }}
                    className='font-xl font-xbold text-lg md:text-[130px] 
                    leading-[3rem] md:leading-[7rem]'>
                        Encounter <br />God
                    </motion.h1>
                    <div className='md:w-8/12 flex flex-col md:flex-row gap-2 md:gap-5 mt-4 md:ml-2' >
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={buttonVariants}
                            transition={{ duration: 1.2, delay: 0.2 }}
                        >
                            <Button
                                icon={<FaLongArrowAltRight/>}
                                isBorder={true}
                            >
                                Visit our locations
                            </Button>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={buttonVariantsRight}
                            transition={{ duration: 1.2, delay: 0.2 }}
                        >
                            <Button
                                icon={<FaLongArrowAltRight />}
                                onClick={handleToggleLiveStreamModal}
                            >
                                join livestream
                            </Button>
                        </motion.div>
                    </div>
                </div>
            </div>
            <div className='absolute top-0 w-full'>
                <DesktopNavigation />
            </div>
            {
                showNavItems && (
                    <div className='md:hidden fixed top-0 w-full h-full left-0'>
                    <MobileNavigation
                        showNavItems={showNavItems}
                        handleToggleShowNavitems={handleToggleShowNavitems}
                    />
                    </div>
                )
            }
           
            {showLiveStreamModal && (
                <div className='fixed top-0 w-full h-full bg-dark/70 z-40'>
                    <LiveStream 
                        handleToggleLiveStreamModal={handleToggleLiveStreamModal}
                    />
                </div>
            )}
        </div>
    </div>
  )
}

export default Hero