import React from 'react'

const Button = ({ children, icon, isBorder, ...props }) => {
  return (
    <button 
    className={`w-full ${isBorder ? 'border border-white bg-transparent hover:bg-choco' 
    : 'bg-choco hover:bg-black/50'} py-4 rounded-md text-white 
    font-bold flex items-center justify-between px-7 ease-in duration-500 capitalize mt-3 text-sm`}
        {...props}>
        { children }
        <span>{icon}</span>
    </button>
  )
}

export default Button