
import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import familyLife  from '../../assets/img/family.jpg'
import womenMinistries  from '../../assets/img/women-1.jpg'
import dramaMinistries  from '../../assets/img/liberty-chapel-sda-drama-4.jpg';

const RevealOnScroll = () => {

  const controls = useAnimation();
  const { ref, inView } = useInView();

  React.useEffect(() => {
      if (inView) {
        controls.start('visible');
      } else {
        controls.start('hidden');
      }
    }, [controls, inView]);

    const variants = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 50 },
      }
    

  return (
    <section className="w-full px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-6xl mx-auto">
      <div>
      <div className='text-center'>
            <h3 className='text-lg font-subPrimary'>our strength</h3>
            <motion.h4 
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ duration: 1.2, delay: 0.2 }}
            className= 'text-lg md:text-xl font-xbold leading-9 md:leading-[4.9rem] my-5'>
                Unity in Diversity
            </motion.h4>
            <motion.p 
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={variants}
                transition={{ duration: 1.2, delay: 0.4 }}
            className=''>
              Join us in fostering unity through diverse activities that strengthen our church community. From worship services to fellowship gatherings and outreach programs, we celebrate diversity while embracing our shared faith. Together, we grow stronger in love, service, and understanding.
            </motion.p>
        </div>
      </div>
      <ShuffleGrid />
    </section>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: familyLife,
  },
  {
    id: 2,
    src: womenMinistries,
  },
  {
    id: 3,
    src: dramaMinistries,
  },
  {
    id: 4,
    src: "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/425379623_809730714531476_3081052960086601666_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeFtHaqK_EXw-1ZU5rQylRpko72eM-kCuDijvZ4z6QK4OKIZVLcv19fvARTEVxos8IZouLgVH3jjEs0oJk8-68q9&_nc_ohc=c32STAjLQREAX8yBwds&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfDs3gr9qhEbyp3NUiNwLM-4FeQEHB81p33R9-1nz8GrQA&oe=65CB6CAD",
  },
  {
    id: 5,
    src: "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/425357897_809730827864798_8663242536191934109_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeGjFIBkpKKrqHfmMgQ1Rhq5INr4mRZs13og2viZFmzXesbHu17H8_CuudkbI3fLSjQX0Y5vW07ZrhEyOiL8lu76&_nc_ohc=MjN9FSnCrC0AX9AYiaT&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfC2MsP-Xee7BjbpqHo9sLXZ8_7TUdM66gWQGp6lF8Wxqg&oe=65CBB37C",
  },
  {
    id: 6,
    src: "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/425374150_809730887864792_4104567725872087877_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHfbUZIdBPhYUU3vx0yWPGozKCivPpB8LzMoKK8-kHwvEmHjNPkd56QApf6BzOI6ZJWtcOGiRgxevMosrf-sCu7&_nc_ohc=stDSV2-7-2YAX_zLfbk&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfAb9oVvjvyHHfc2zetaT3udD3FVh50UCWQs03Rpzqgl-w&oe=65CBF2F7",
  },
  {
    id: 7,
    src: "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/425353720_809730727864808_4904919514178227042_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHb-isOYqkQdcAFCYBBDeL4Z_Qdl-LtKqdn9B2X4u0qp2AbqsPbhTFOyFjZVjYJZbAUvyHKnNlEppXhMwQKlJw3&_nc_ohc=SMT4mqELaUkAX_X9s2L&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfB7wOWpA74PNVAKOXhuPrkWb_r7jz4kTwohiQOjY8g-UA&oe=65CBDDB0",
  },
  {
    id: 8,
    src: "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/425345966_809731054531442_5850185576136552476_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHL5dvvlFoEXVhNE6vP-wxAAgzvdnzOtzwCDO92fM63PMzjWu4PNbnwEFgBd94Ipm8GAt8vMnSgzRDIw46KMTYV&_nc_ohc=CzesCeQnWjwAX_URjqZ&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfAOUn1OJNL7gCH6LMrgp5wrbdaJ-Fgw0-b_3NBoI6PcYg&oe=65CA1B60",
  },
  {
    id: 9,
    src: "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/424585143_809186121252602_5698150120314760399_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHAtjmYyAXCbJ5bAgsprQQLSsPBFw0wGzRKw8EXDTAbNACTfY2OWp0jpoAY8aLfZfQ7x2exFazl6HnT1Fbv487E&_nc_ohc=_MCbLxGmj1MAX_4rhhk&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfCi23kVBRZ_Jys5TUkE-mFcKOjBMwe9pP3nP8n1s4C-Gw&oe=65CB5832",
  },
  {
    id: 10,
    src: "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/424768662_809185627919318_1380778499796440572_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHqIoJitXQcF39ZjPAbKxGGJuiJ8eS_CNEm6Inx5L8I0SF03T8jIPuuVhSci4ovfDdau-5WA4_AYp-stPFIyug3&_nc_ohc=eBnRVTFcO_sAX9rXWZU&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfAiWsTJ1LkkwTLXS2sLy-_zFtmrasIQK5X6CKVRsLDM_Q&oe=65CBA90F",
  },
  {
    id: 11,
    src: "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/424621977_809185977919283_5599992425872258274_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHK1D0z7Og33nf1nOek7eLGFLZlLOd7EEcUtmUs53sQR0BsFyH5ZCnZ06cyqtOuv8ZA4P-vupaL52p44t7JuwTA&_nc_ohc=wPqppeMXphMAX96JFjF&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfBFvnhv-DPxfClgAjeaxcPT9w0xo27RHa3WwEcZIphU9g&oe=65CADC92",
  },
  {
    id: 12,
    src: "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/424721349_809731041198110_7735276912082731230_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeELzuMfa_hSUJ9z8PnfEn8cUrtee57ZnrBSu157ntmesKmuwploLP6FnatxVQ4Fp5F4_Pz2bEJKKaiOJjLD3j2x&_nc_ohc=OES8ryBBhW8AX-C_NN7&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfD5aPqfhJWpW-RlY1FIXt63eTEkl22IgtsAGLfLSl6czA&oe=65CC0178",
  },
  {
    id: 13,
    src: "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/417902136_794248226079725_5014124611413227396_n.jpg?stp=dst-jpg_p960x960&_nc_cat=107&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeELX6lbB1nDrKFuVs5hAuyDN99Dt3XeiRg330O3dd6JGAJjD-R3nAwUfwdNYhYJqHrNRLiQ3bRgfV-Ku1LTkPOz&_nc_ohc=iuSpC8STqnsAX-FKhQu&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfAXYu1xxtaTAKY1kvGqKkTSmTP2KZTUvF4l8okQgH2wyg&oe=65CA3B10",
  },
  {
    id: 14,
    src: "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/418102284_794243609413520_7299914752204542732_n.jpg?stp=dst-jpg_p960x960&_nc_cat=108&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeGbgww2zwwpxJEUR3WNzzmVEK6z2Q62WYgQrrPZDrZZiMfxb0x00_ZFaR3S_g-USVx4iu5_pGjvERwx7Hco5TbK&_nc_ohc=wKNRa3ifCpgAX8QE-7J&_nc_oc=AQll02-Dzl9lTuBiuTitcv5qqD-FnJ2Pz2jHbvStfqaMPMXxm_rOOTV1N73BK5w3jbg&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfATllJfuvdGNDQdvBusqBATW95fUk6WIyg537s2kMjT0A&oe=65CBABC8",
  },
  {
    id: 15,
    src: "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/414482932_790807449757136_493455363523199945_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=783fdb&_nc_eui2=AeFs3mW01CgNnkOHEqV6gnb3TsiJh_E5SuNOyImH8TlK41pYM-skUVKoRptBP8JgfQaNd5PqCtK4_NL_UthLb_Xg&_nc_ohc=bnjlLkHYAvcAX9ngLoy&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfBzzRUIv8xlYJ7BPksJ0PCsjXsxQQruhciWaUSfYApLhQ&oe=65CBBB50",
  },
  {
    id: 16,
    src: "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/415205058_786820016822546_9032778880978786308_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeH5NamhgTdVRFh2QCDVDPIQEqHkXzHchIQSoeRfMdyEhFbnlhdXoC7eVXi3oWoNBpEZjBBHMO8m6WDlf10QgTgb&_nc_ohc=6EJjFliLBjAAX9MOt7o&_nc_oc=AQmAUA9UwMzKU0CmG-6ttNUxPE4HY-ghbg0Dhx0cNoElguMC8ulaLEKGTxsS5d134Zo&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfCskfyUMwzHllpFeBnducvyyOA0mhs0tPwdlGV0GbbuAg&oe=65CA9731",
  },
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="w-full h-full"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: "cover",
      }}
    ></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid grid-cols-4 grid-rows-4 h-[450px] gap-1">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default RevealOnScroll;