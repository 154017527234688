import React from 'react';
import { MinistriesCardItem } from './';
import familyLife  from '../../assets/img/family.jpg'
import womenMinistries  from '../../assets/img/women-1.jpg'
import dramaMinistries  from '../../assets/img/liberty-chapel-sda-drama-4.jpg';
import bibleSchool  from '../../assets/img/bible-school.jpg';
import youthMinistries  from '../../assets/img/youths.jpg';
import childrenMinistries  from '../../assets/img/children-scaled.jpg';

const MinistriesCategoryCard = () => {
  return (
    <div className='p-5 font-primary cursor-pointer'>
      <div className="grid grid-cols-4 gap-1">
        <div className='relative h-80'>
          <MinistriesCardItem
            image={familyLife}
            title='family life'
          />
        </div>
        <div className='relative h-80'>
          <MinistriesCardItem
              image={womenMinistries}
              title='Women Ministry'
            />
        </div>
        <div className="col-span-2 relative h-80">
          <MinistriesCardItem
              image={dramaMinistries}
              title='Drama Ministry'
            />
        </div>
        <div className="col-span-2 relative">
          <MinistriesCardItem
              image={bibleSchool}
              title='Bible School'
            />
        </div>
        <div className="relative">
          <MinistriesCardItem
              image={youthMinistries}
              title='Youth Ministry'
            />
        </div>
        <div className="relative">
          <MinistriesCardItem
              image={childrenMinistries}
              title="Children's Ministry"
            />
        </div>
      </div>
    </div>
  )
}

export default MinistriesCategoryCard