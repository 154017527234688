import React from 'react';
import { AboutSection, AboutPastor } from './about';
import { Ministriescard } from '../cards/';
import { MinistrieCategories } from './ministries';
import { ContactSection } from './contact';
import { WorshipSession } from './others';
import { MouseImage } from '../test';
import dramaImg from '../../assets/img/liberty-chapel-sda-drama-3.jpg';
import bibleSchoolGrads from '../../assets/img/school.jpg';
import adventistYouths from '../../assets/img/youths-3.jpg';
import congregation from '../../assets/img/congregation.jpg';
import prayingWoman from '../../assets/img/praying-woman.jpg';


const Sections = () => {
  return (
    <>
        <MouseImage />
        <AboutSection />
        <Ministriescard
          image={dramaImg}
          subTitle='ministry'
          title='Drama Ministry'
          content='
          The Church Drama Unit is an endeavor born out of our desire to explore the depths of faith, 
          spirituality, and the human experience through the art of theater. 
          This unit will serve as a platform for our talented members, 
          young and old, to showcase their creative gifts and bring to 
          life stories that touch our souls and challenge our perspectives
          '
        />
        <Ministriescard
          image={bibleSchoolGrads}
          subTitle='ministry'
          title='Bible School'
          content='Attending a Bible school offers numerous benefits. It provides a deep understanding of Scripture, 
          strengthens faith, fosters spiritual growth, equips for ministry, builds a community of believers, 
          and encourages lifelong learning. Through rigorous study, reflection, and practical training, 
          Bible school enriches individuals’ lives and prepares them for serving God and others.'
        />
        <Ministriescard
          image={adventistYouths}
          subTitle='ministry'
          title='Youth Ministry'
          content='Church youth ministry plays a crucial role in the lives of young individuals. 
          It offers a supportive community, fosters spiritual growth, and provides a platform for 
          young people to explore and deepen their faith. Youth ministry encourages fellowship, 
          offers guidance, and equips young individuals to navigate challenges while empowering 
          them to make a positive impact in their communities.'
        />
        <AboutPastor />
        <MinistrieCategories />
        <WorshipSession
          title='finding peace in jesus'
          content='In the chaos of life, there is solace in Jesus. 
          In moments of despair, Jesus offers comfort and hope. By surrendering our burdens to Him, 
          we discover a tranquility that surpasses understanding. In Jesus, we find a refuge from the storms of life, 
          an unwavering source of peace that calms our hearts. '
        />
        <Ministriescard
          image={congregation}
          subTitle='Be a part of'
          title='The joy'
          content='You have enlarged the nation and increased their joy; they rejoice 
          before you as people rejoice at the harvest, as warriors rejoice when dividing the plunder. – Isaiah 9:3'
        />
        <Ministriescard
          image={prayingWoman}
          subTitle='The All New'
          title='The worship'
          content='The LORD lives; and blessed be my rock; and exalted be the God of the rock of my salvation.'
          centered={true}
        />
        <ContactSection />
    </>
  )
}

export default Sections