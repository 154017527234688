import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  width: '98vw',
  height: '60vh',
};
const center = {
  lat: 9.064506024080655, // default latitude
  lng: 7.442698589618257, // default longitude
};
// 9.064506024080655, 7.442698589618257
const GoogleMapLocations = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDIvM0fru-ZE6HNxtFFzeU3Ra-cKzd2CEg',
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={17}
        center={center}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
  );
};

export default GoogleMapLocations;