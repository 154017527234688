import React, { useEffect } from 'react';
import { FaLongArrowAltRight } from "react-icons/fa";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Button } from '../button';
import '../../assets/styles/style.css'


const PageLabel = ({ image, label, subLabel, centered }) => {

    const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }
      

  return (
    <div className='h-1/2 relative'>
        <div className="half-image bg-fixed" style={{ backgroundImage: `url(${image})` }}>
        </div>
        <div className='w-full h-full flex flex-col justify-center items-center 
        absolute left-0 top-0 bg-gradient-to-b from-blue/25 to-blue/100 font-primary'>
            <div className={`${centered ? 'w-full' : 'w-9/12 m-auto'} `}>
                <div className={centered ? 'text-center text-whiteSmoke' : 'text-whiteSmoke'}>
                  <hr className='w-4/12 bg-white h-1' />
                    <motion.h4 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.2 }}
                    className={`md:w-${centered ? 'full text-center' : '4/12'} 
                    text-[2.7rem] md:text-xl font-xbold leading-9 md:leading-[4.9rem] my-5`}>
                        {label}
                    </motion.h4>
                    <motion.h3 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.4 }}
                    className='text-sm md:text-lg font-subPrimary'>{subLabel}</motion.h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PageLabel