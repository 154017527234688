import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaCheckCircle } from "react-icons/fa";


const ImageBackground = ({ children }) => {

    const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }
      
    const listItems = [
        'Love and Joy',
        'Peace and Patience',
        'Kindness and Goodness',
        'Faithfulness and Gentleness'
    ]
  return (
    <div className='h-auto md:h-screen bg-heroImage bg-cover bg-no-repeat bg-center font-primary py-10 md:py-0 relative'>
        <div className='h-full flex flex-col md:flex-row md:items-center md:justify-center gap-10 md:gap-0 bg-white/80 font-primary'>
            { children }
        </div>
    </div>
  )
}

export default ImageBackground