import React, { useState } from 'react';
import { Button } from '../button';
import { toast } from 'react-toastify';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name:'',
        phone:'',
        email:'',
        date:'',
        time:''
    })
    const placeholders = {
        name:'Your Name',
        phone:'Tel',
        email:'email address',
        date:'',
        time:''
    }
    const labels = {
        name:'Your Name',
        phone:'phone',
        email:'email address',
        date:'date',
        time:'time'
    }

    const handleContactFormChange = (e)=>{
        const { name, value } = e.target;
        setFormData({ ...formData, [name]:value})
    }

    const requiredFields = [
        'name',
        'phone',
        'email',
        'date',
        'time'
    ]
    const handleSubmit = ()=>{
        for(const field of requiredFields){
            if(!formData[field]){
                toast.error(`${field} field cannot be empty`);
                return
            }
        }
        console.log('submitted');
    }
  return (
    <div className='bg-white rounded-md'>
        <div className='p-10'>
        <h2 className='text-sm text-darkBlue md:text-lg font-xbold leading-[1.6rem] md:leading-[3rem] capitalize'>make an appointment</h2>
            <div className='my-10'>
                <div className='flex flex-col md:flex-row md:items-center gap-5 w-full'>
                    <div className='md:w-1/2'>
                        <label htmlFor={placeholders.name}>{labels.name}</label>
                        <input 
                            type="text"
                            name='name'
                            value={formData.name}
                            placeholder={placeholders.name}
                            className='w-full border outline-none py-3 px-3 rounded-md'
                            onChange={handleContactFormChange}
                        />
                    </div>
                    <div className='md:w-1/2'>
                        <label htmlFor={placeholders.phone} className='capitalise'>{labels.phone}</label>
                        <input 
                            type="number"
                            name='phone'
                            value={formData.phone}
                            placeholder={placeholders.phone}
                            className='w-full border outline-none py-3 px-3 rounded-md'
                            onChange={handleContactFormChange}
                        />
                    </div>
                </div>
                <div className='my-3'>
                    <label htmlFor={placeholders.email}>{labels.email}</label>
                    <input 
                        type="email"
                        name='email'
                        value={formData.email}
                        placeholder={placeholders.email}
                        className='w-full border outline-none py-3 px-3 rounded-md'
                        onChange={handleContactFormChange}
                    />
                </div>
                <div className='flex flex-col md:flex-row md:items-center gap-5 w-full'>
                    <div className='md:w-1/2'>
                        <label htmlFor={placeholders.date} className='capitalise'>{labels.date}</label>
                        <input 
                            type="date"
                            name='date'
                            value={formData.date}
                            placeholder={placeholders.date}
                            className='w-full border outline-none py-3 px-3 rounded-md'
                            onChange={handleContactFormChange}
                        />
                    </div>
                    <div className='md:w-1/2'>
                        <label htmlFor={placeholders.time} className='capitalise'>{labels.time}</label>
                        <input 
                            type="time"
                            name='time'
                            value={formData.time}
                            placeholder={placeholders.time}
                            className='w-full border outline-none py-3 px-3 rounded-md'
                            onChange={handleContactFormChange}
                        />
                    </div>
                </div>
            </div>
            <div className='8/12'>
                <button 
                onClick={handleSubmit}
                className='bg-blue py-3 rounded-md text-whiteSmoke font-primary text-sm font-normal w-full'>submit form</button>
            </div>
        </div>
        
    </div>
  )
}

export default ContactForm