import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import pastorKayImage  from '../../../assets/img/pastor-main.jpg';



const AboutSection = () => {

  const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }

  
  return (
    <div className='md:h-screen flex items-center flex-col-reverse md:flex-row font-primary px-3 md:px-0'>
      <div className='w-full h-full md:w-1/2 flex  items-center justify-center'>
        <img src={pastorKayImage} className='h-2/3 object-cover rounded-full' alt="pastor kayode Arigbede" />
      </div>
      <div className='w-full md:w-1/2 h-1/2 md:h-full flex items-center justify-center pt-10 md:pt-0'>
          <div className='md:w-10/12 text-darkBlue'>
            <motion.h3 
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ duration: 1.2, delay: 0.2 }}
            className='text-center capitalize font-xbold text-lg md:text-xl leading-[2.6rem] md:leading-[4.8rem]'>
              Jesus changes everything
            </motion.h3>
            <motion.p 
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={variants}
              transition={{ duration: 1.2, delay: 0.4 }}
            className='text-center my-5 text-sm'>
            Jesus changes everything. His life, teachings, death, and resurrection have transformed 
            the course of human history. Through his message of love, forgiveness, and redemption, 
            he has given hope to the hopeless, healing to the broken, and purpose to the lost. 
            Jesus’ sacrificial act on the cross has reconciled humanity with God, offering salvation 
            and eternal life to all who believe. His teachings of compassion, humility, and 
            selflessness have inspired countless individuals to live lives of service and kindness. 
            Jesus’ transformative power extends beyond religion, impacting cultures, societies, and individuals, 
            reminding us that in him, we find the power to change ourselves and the world around us.
            </motion.p>
          </div>
      </div>
    </div>
  )
}

export default AboutSection