import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { MouseImage } from '../components/test';


const Section = ({ backgroundColor, children }) => {
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

const RevealOnScroll = ({ children }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }}
      transition={{ duration: 0.5, delay: 1 }}
    >
      {children}
    </motion.div>
  );
};

const Giving = () => {
  return (
    <div>
      <Section backgroundColor="lightblue">
        <h1>Section 1</h1>
      </Section>
      <Section backgroundColor="lightcoral">
        <h1>Section 2</h1>
      </Section>
      <Section backgroundColor="lightgreen">
        <RevealOnScroll>
          <h2>Section 3 - Scroll down to see the animation</h2>
        </RevealOnScroll>
      </Section>
      <Section backgroundColor="lightsalmon">
        <RevealOnScroll>
          <h2>Section 4 - Scroll down to see the animation</h2>
        </RevealOnScroll>
      </Section>
      <Section backgroundColor="lightyellow">
        <h1>Section 5</h1>
      </Section>
    </div>
  );
};

export default Giving;
