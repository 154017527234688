import { useAnimate } from "framer-motion";
import React, { useRef } from "react";
import { FiMousePointer } from "react-icons/fi";
import congregation from '../../assets/img/congregation.jpg';
import { Hero } from '../../components/hero';
import RevealOnScroll from "./RevealOnScroll";

export const MouseImage = () => {
  return (
    <MouseImageTrail
      renderImageBuffer={50}
      rotationRange={25}
      images={[
        congregation,
        "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/425379623_809730714531476_3081052960086601666_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeFtHaqK_EXw-1ZU5rQylRpko72eM-kCuDijvZ4z6QK4OKIZVLcv19fvARTEVxos8IZouLgVH3jjEs0oJk8-68q9&_nc_ohc=c32STAjLQREAX8yBwds&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfDs3gr9qhEbyp3NUiNwLM-4FeQEHB81p33R9-1nz8GrQA&oe=65CB6CAD",
        "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/425357897_809730827864798_8663242536191934109_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeGjFIBkpKKrqHfmMgQ1Rhq5INr4mRZs13og2viZFmzXesbHu17H8_CuudkbI3fLSjQX0Y5vW07ZrhEyOiL8lu76&_nc_ohc=MjN9FSnCrC0AX9AYiaT&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfC2MsP-Xee7BjbpqHo9sLXZ8_7TUdM66gWQGp6lF8Wxqg&oe=65CBB37C",
        "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/425374150_809730887864792_4104567725872087877_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHfbUZIdBPhYUU3vx0yWPGozKCivPpB8LzMoKK8-kHwvEmHjNPkd56QApf6BzOI6ZJWtcOGiRgxevMosrf-sCu7&_nc_ohc=stDSV2-7-2YAX_zLfbk&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfAb9oVvjvyHHfc2zetaT3udD3FVh50UCWQs03Rpzqgl-w&oe=65CBF2F7",
        "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/425353720_809730727864808_4904919514178227042_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHb-isOYqkQdcAFCYBBDeL4Z_Qdl-LtKqdn9B2X4u0qp2AbqsPbhTFOyFjZVjYJZbAUvyHKnNlEppXhMwQKlJw3&_nc_ohc=SMT4mqELaUkAX_X9s2L&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfB7wOWpA74PNVAKOXhuPrkWb_r7jz4kTwohiQOjY8g-UA&oe=65CBDDB0",
        "/https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/425345966_809731054531442_5850185576136552476_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHL5dvvlFoEXVhNE6vP-wxAAgzvdnzOtzwCDO92fM63PMzjWu4PNbnwEFgBd94Ipm8GAt8vMnSgzRDIw46KMTYV&_nc_ohc=CzesCeQnWjwAX_URjqZ&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfAOUn1OJNL7gCH6LMrgp5wrbdaJ-Fgw0-b_3NBoI6PcYg&oe=65CA1B60",
        "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/424585143_809186121252602_5698150120314760399_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHAtjmYyAXCbJ5bAgsprQQLSsPBFw0wGzRKw8EXDTAbNACTfY2OWp0jpoAY8aLfZfQ7x2exFazl6HnT1Fbv487E&_nc_ohc=_MCbLxGmj1MAX_4rhhk&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfCi23kVBRZ_Jys5TUkE-mFcKOjBMwe9pP3nP8n1s4C-Gw&oe=65CB5832",
        "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/424768662_809185627919318_1380778499796440572_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHqIoJitXQcF39ZjPAbKxGGJuiJ8eS_CNEm6Inx5L8I0SF03T8jIPuuVhSci4ovfDdau-5WA4_AYp-stPFIyug3&_nc_ohc=eBnRVTFcO_sAX9rXWZU&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfAiWsTJ1LkkwTLXS2sLy-_zFtmrasIQK5X6CKVRsLDM_Q&oe=65CBA90F",
        "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/424621977_809185977919283_5599992425872258274_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHK1D0z7Og33nf1nOek7eLGFLZlLOd7EEcUtmUs53sQR0BsFyH5ZCnZ06cyqtOuv8ZA4P-vupaL52p44t7JuwTA&_nc_ohc=wPqppeMXphMAX96JFjF&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfBFvnhv-DPxfClgAjeaxcPT9w0xo27RHa3WwEcZIphU9g&oe=65CADC92",
        "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/424721349_809731041198110_7735276912082731230_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeELzuMfa_hSUJ9z8PnfEn8cUrtee57ZnrBSu157ntmesKmuwploLP6FnatxVQ4Fp5F4_Pz2bEJKKaiOJjLD3j2x&_nc_ohc=OES8ryBBhW8AX-C_NN7&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfD5aPqfhJWpW-RlY1FIXt63eTEkl22IgtsAGLfLSl6czA&oe=65CC0178",
        "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/417902136_794248226079725_5014124611413227396_n.jpg?stp=dst-jpg_p960x960&_nc_cat=107&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeELX6lbB1nDrKFuVs5hAuyDN99Dt3XeiRg330O3dd6JGAJjD-R3nAwUfwdNYhYJqHrNRLiQ3bRgfV-Ku1LTkPOz&_nc_ohc=iuSpC8STqnsAX-FKhQu&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfAXYu1xxtaTAKY1kvGqKkTSmTP2KZTUvF4l8okQgH2wyg&oe=65CA3B10",
        "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/418102284_794243609413520_7299914752204542732_n.jpg?stp=dst-jpg_p960x960&_nc_cat=108&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeGbgww2zwwpxJEUR3WNzzmVEK6z2Q62WYgQrrPZDrZZiMfxb0x00_ZFaR3S_g-USVx4iu5_pGjvERwx7Hco5TbK&_nc_ohc=wKNRa3ifCpgAX8QE-7J&_nc_oc=AQll02-Dzl9lTuBiuTitcv5qqD-FnJ2Pz2jHbvStfqaMPMXxm_rOOTV1N73BK5w3jbg&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfATllJfuvdGNDQdvBusqBATW95fUk6WIyg537s2kMjT0A&oe=65CBABC8",
        "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/414482932_790807449757136_493455363523199945_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=783fdb&_nc_eui2=AeFs3mW01CgNnkOHEqV6gnb3TsiJh_E5SuNOyImH8TlK41pYM-skUVKoRptBP8JgfQaNd5PqCtK4_NL_UthLb_Xg&_nc_ohc=bnjlLkHYAvcAX9ngLoy&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfBzzRUIv8xlYJ7BPksJ0PCsjXsxQQruhciWaUSfYApLhQ&oe=65CBBB50",
        "https://scontent.flos1-2.fna.fbcdn.net/v/t39.30808-6/415228027_786819993489215_2340952720060308879_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeELK5Af_wxvIHJZ8Zps3PdXS0B-32WaIVpLQH7fZZohWhx0-hqlfrsvMwiexku_6XRWACybe2mxUibWBQowGVR_&_nc_ohc=tYAprgyxeYcAX9dUM62&_nc_zt=23&_nc_ht=scontent.flos1-2.fna&oh=00_AfBDQol37lm6CYUEf2zBZMnp_7bEAovxRniRsSfGZvawxw&oe=65CBCA40",
        "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/415258825_786820150155866_6673615070889581174_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeFXUo9J271CqOsiykCJFfXxX59Uz2C9wItfn1TPYL3Ai7uqbQez49Of-fm1sFHnHQr5RTD7nINyGRCiFTjiWiJp&_nc_ohc=BqUf0CeQpukAX-Ex34e&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfBcm2H6gIZynoYo0j08atr8J_c0AdzPKgI1X6-f0k_YgA&oe=65CA5F02",
        "https://scontent.flos1-1.fna.fbcdn.net/v/t39.30808-6/415224022_786820510155830_6766064384103087235_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeHHlpIqu8PveWM4FWK0EmogoqbfUbVTOFaipt9RtVM4Vuw_oRbJkvepXLizNwLkH3OsBKhZ4SQSc4zU52Cz46je&_nc_ohc=076sap9suGAAX8heOWZ&_nc_zt=23&_nc_ht=scontent.flos1-1.fna&oh=00_AfCR7SVUd-VJnmReaTO1bgTbfhLKoXVipfoAhlVUUxgEyg&oe=65CA2E93",
      ]}
    >
      {/* <section className="grid h-screen w-full place-content-center bg-white">
        <p className="flex items-center gap-2 text-3xl font-bold uppercase text-black">
          <FiMousePointer />
          <span>Hover me</span>
        </p>
      </section> */}
      <RevealOnScroll />
    </MouseImageTrail>
  );
};

const MouseImageTrail = ({
  children,
  // List of image sources
  images,
  // Will render a new image every X pixels between mouse moves
  renderImageBuffer,
  // images will be rotated at a random number between zero and rotationRange,
  // alternating between a positive and negative rotation
  rotationRange,
}) => {
  const [scope, animate] = useAnimate();

  const lastRenderPosition = useRef({ x: 0, y: 0 });
  const imageRenderCount = useRef(0);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;

    const distance = calculateDistance(
      clientX,
      clientY,
      lastRenderPosition.current.x,
      lastRenderPosition.current.y
    );

    if (distance >= renderImageBuffer) {
      lastRenderPosition.current.x = clientX;
      lastRenderPosition.current.y = clientY;

      renderNextImage();
    }
  };

  const calculateDistance = (x1, y1, x2, y2) => {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;

    // Using the Pythagorean theorem to calculate the distance
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    return distance;
  };

  const renderNextImage = () => {
    const imageIndex = imageRenderCount.current % images.length;
    const selector = `[data-mouse-move-index="${imageIndex}"]`;

    const el = document.querySelector(selector);

    el.style.top = `${lastRenderPosition.current.y}px`;
    el.style.left = `${lastRenderPosition.current.x}px`;
    el.style.zIndex = imageRenderCount.current.toString();

    const rotation = Math.random() * rotationRange;

    animate(
      selector,
      {
        opacity: [0, 1],
        transform: [
          `translate(-50%, -25%) scale(0.5) ${
            imageIndex % 2
              ? `rotate(${rotation}deg)`
              : `rotate(-${rotation}deg)`
          }`,
          `translate(-50%, -50%) scale(1) ${
            imageIndex % 2
              ? `rotate(-${rotation}deg)`
              : `rotate(${rotation}deg)`
          }`,
        ],
      },
      { type: "spring", damping: 15, stiffness: 200 }
    );

    animate(
      selector,
      {
        opacity: [1, 0],
      },
      { ease: "linear", duration: 0.5, delay: 5 }
    );

    imageRenderCount.current = imageRenderCount.current + 1;
  };

  return (
    <div
      ref={scope}
      className="relative overflow-hidden"
      onMouseMove={handleMouseMove}
    >
      {children}

      {images.map((img, index) => (
        <img
          className="pointer-events-none absolute left-0 top-0 h-48 w-auto rounded-xl border-2 border-black bg-neutral-900 object-cover opacity-0"
          src={img}
          alt={`Mouse move image ${index}`}
          key={index}
          data-mouse-move-index={index}
        />
      ))}
    </div>
  );
};


export default MouseImage;