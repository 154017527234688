import React from 'react'
import { MdLocationOn } from "react-icons/md";
import { IoMail } from "react-icons/io5";
import { CiPhone } from "react-icons/ci";
import { Socials } from '../social';
const TopHeader = () => {
  return (
    <div className='hidden  md:flex items-center justify-around bg-choco font-primary py-3'>
        <ul className='flex items-center gap-5'>
            <li className='flex items-center gap-2 text-white text-xsm'><span><MdLocationOn/></span>2 wennike Briggs close Utako</li>
            <li className='flex items-center gap-2 text-white text-xsm'><span><IoMail/></span>libertychapel@gmail.com</li>
            <a href="tel:07061091667" className='flex items-center gap-2 text-white text-xsm'><span><CiPhone/></span>07061091667</a>
        </ul>
        <div>
            <Socials/>
        </div>
    </div>
  )
}

export default TopHeader