import React, { useState } from 'react';
import {
    FaList,
    FaUserPlus,
    FaShare,
    FaPlusCircle,
    FaMinusCircle,
  } from "react-icons/fa";

  import { IoIosArrowDropdownCircle } from "react-icons/io";
  import { RiArrowRightCircleFill } from "react-icons/ri";


  const faqs = [
    {
      question: "What time are your worship services?",
      answer: "Our worship services are held every Saturday at 9:00 AM to 11:00 AM. \n Midweek Service (Wednesdays): 6:00PM - 7:00PM",
    },
    {
      question: "How can I get involved in volunteering at the church?",
      answer:
      "We welcome volunteers in various ministries such as hospitality, children’s ministry, music, and more. Please visit our Volunteer Opportunities page to learn more and sign up.",
    },
    {
      question: "What denomination is the church affiliated with?",
      answer: "While we hold to the beliefs and values of our denomination, we welcome people from all backgrounds to worship with us and participate in our community.",
    },
    {
      question: "How can I request pastoral care or counseling?",
      answer: "f you are in need of pastoral care or counseling, please reach out to our church office. Our pastoral staff is available to provide support, guidance, and prayer during times of need. We also have resources and referrals available for additional support if needed.",
    },
  ];
  

const FAQS = () => {

const [toggleFaq, setToggleFaq] = useState(null);
  
  const toggleQuestionList = (index) => {
    if (toggleFaq === index) {
      return setToggleFaq(null);
    }
    return setToggleFaq(index);
  };


  return (
    <div className="w-full bg-white mt-12 p-5 rounded-[15px] grid grid-cols-1 gap-y-5">
        {faqs.map((faq, index) => (
          <div key={index} className='cursor-pointer'>
            <h2
              onClick={() => toggleQuestionList(index)}
              className={`flex items-center gap-2 justify-between border-t
              font-normal text-xsm px-5 py-3 ${toggleFaq === index && 'text-yellow'}`}
            >
              {faq.question}
              <span className="cursor-pointer">
                {toggleFaq === index ? <IoIosArrowDropdownCircle /> : <RiArrowRightCircleFill />}
              </span>
            </h2>
            <p
              className={`${
                toggleFaq === index ? "" : "hidden"
              } px-5 text-xsm py-2`}
            >
              {faq.answer}
            </p>
          </div>
        ))}
      </div>
  )
}

export default FAQS