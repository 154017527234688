import React, { useState, createContext, useContext } from 'react'


const MobileNavigationContext = createContext();
export const MobileNavigationContextProvider = ({ children }) => {

    const [showMobileNavItems, setShowMobileNavItems] = useState(false);
    const handleShowMobileNavItems = ()=>{
        setShowMobileNavItems(!showMobileNavItems);
    }
    const val = 'test val'
  return (
    <MobileNavigationContext.Provider value={{
        showMobileNavItems,
        handleShowMobileNavItems,
        val
    }}>
        {children}
    </MobileNavigationContext.Provider>
  )
}

export const useMobileNavigationContext = ()=> useContext(MobileNavigationContext)

