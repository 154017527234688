import React from 'react';
import { TopHeader } from '../components/header';
import { Hero } from '../components/hero';
import { Sections } from '../components/sections';


const LandingPage = () => {
  return (
    <div className=''>
      <TopHeader />
      <Hero />
      <Sections />
    </div>
  )
}

export default LandingPage