import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaInstagram } from "react-icons/fa6";
import { FaTwitter, FaYoutube, FaVideo  } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";

const Socials = () => {
  const SocialAccounts = [
    {
        name:'facebook',
        url:'https://m.facebook.com/LibertyChapelOnline/live',
        icon:<FaFacebookF className=''/>
    },
    {
        name:'youtube',
        url:'https://www.youtube.com/watch?v=1ufWTGWnkfQ',
        icon:<FaYoutube className='' />
    },
    {
        name:'Zoom',
        url:'https://us02web.zoom.us/j/81657267250#success',
        icon:<FaVideo  className=''/>
    },
    {
        name:'instagram',
        url:'https://www.instagram.com/lc_sda/',
        icon:<FaInstagram className='' />
    },
    
]



  return (
    <ul className='flex items-center gap-5'>
        {
            SocialAccounts.map((item, index)=>(
            <a 
              href={item.url}
              key={index}
              className='flex items-center gap-2 text-white cursor-pointer hover:text-yellow ease-in duration-500'
            >
              <span>{item.icon}</span></a>
            ))
          }
    </ul>
  )
}

export default Socials