import React from 'react'

const MinistriesCardItem = ({ image, title }) => {
  return (
    <>
        <img src={image} alt="" className='object-cover h-full w-full' />
        <div className='h-full absolute top-0 left-0 w-full flex items-center justify-center bg-gradient-to-b from-blue/25 to-blue/100'>
            <h3 className='px-2 md:px-0 text-xsm md:text-[1.8rem] font-bold text-whiteSmoke font-primary'>{title}</h3>
        </div>
    </>
  )
}

export default MinistriesCardItem