import React, { useState } from 'react';
import '../assets/styles/style.css'
import { useMobileNavigationContext } from '../contexts/mobileNavigationContext';
import { TopHeader } from '../components/header';
import { PageLabel } from '../components/page-label';
import { Ministriescard } from '../components/cards';
import { DesktopNavigation, MobileNavigation, MobileNavigationHeader } from '../components/header';
import { WorshipSession } from '../components/sections/others';
import { MinistrieCategories } from '../components/sections/ministries';


import adventistYouths from '../assets/img/youths-3.jpg';
import congregation from '../assets/img/happy-church-members-liberty-chapel.jpg';
import members from '../assets/img/members.jpg';
import churchWorship from '../assets/img/church-worship.jpg';


const About = () => {
  const { showMobileNavItems, handleShowMobileNavItems } = useMobileNavigationContext();

  return (
    <div>
      <div className='w-full'>
          <DesktopNavigation />
      </div>
      <MobileNavigationHeader 
          handleToggleShowNavitems={handleShowMobileNavItems}
      />
      <PageLabel
        image={congregation}
        label='About Us'
        subLabel='Liberty Chapel Seventh-Day Adventist Church'
      />

      <WorshipSession
        title='Our Mission'
        content='At Liberty Chapel, our mission is to glorify God by loving Him, loving one another, 
        and loving our neighbors. We strive to create an atmosphere of genuine acceptance, where everyone 
        feels valued and embraced for who they are. We are dedicated to nurturing spiritual growth, 
        fostering authentic relationships, and equipping individuals to make a difference in their communities.'
      />

      <Ministriescard
          image={members}
          subTitle=''
          title='Our Beliefs'
          content="
          We are a Christ-centered church, rooted in the foundational truths of the Bible. We believe in the triune 
          God—Father, Son, and Holy Spirit—and in the saving grace of Jesus Christ. Our faith compels us to live out our 
          beliefs through acts of compassion, justice, and service. We are committed to learning and growing in 
          our understanding of God's Word, seeking to apply its timeless wisdom to our daily lives."
        />
      
      <WorshipSession
        title='Our Worship'
        content='At Liberty Chapel, worship is at the heart of all we do. 
        Through vibrant and meaningful worship experiences, we seek to honor 
        God and encounter His presence. Our worship services blend contemporary and traditional elements, 
        creating an atmosphere of reverence and joy. 
        We believe that worship is not confined to a single hour on Sundays but extends to every aspect of our lives.'
        isImageSingle={true}
        singleImage={churchWorship}
      />

      <MinistrieCategories />

      {
                showMobileNavItems && (
                    <div className='md:hidden fixed top-0 w-full h-full left-0'>
                    <MobileNavigation
                        showNavItems={showMobileNavItems}
                        handleToggleShowNavitems={handleShowMobileNavItems}
                    />
                    </div>
                )
            }
    </div>
  );
};

export default About;
