import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaCheckCircle } from "react-icons/fa";
import pastorAndWife from '../../../assets/png/pastor-wife-removebg-preview.png';


const AboutPastor = () => {

    const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }
      
    const listItems = [
        'Love and Joy',
        'Peace and Patience',
        'Kindness and Goodness',
        'Faithfulness and Gentleness'
    ]
  return (
    <div className='h-auto md:h-screen bg-heroImage bg-cover bg-no-repeat bg-center font-primary py-10 md:py-0 relative'>
        <div className='h-full flex flex-col md:flex-row md:items-center md:justify-center gap-10 md:gap-0 bg-white/80 font-primary'>
            <div className='h-1/2 md:w-1/2 px-10 md:px-0'>
                <div className='h-full md:w-9/12 m-auto'>
                    <motion.h2
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.2 }}
                    className='text-lg text-darkBlue md:text-xl font-xbold leading-[2.6rem] md:leading-[4rem]'>
                        Meet our pastor
                    </motion.h2>
                    <motion.p 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.4 }}
                    className='py-3 text-sm my-3'>
                    <b className='text-darkBlue'>Pastor Kayode Arigbede</b> is a dedicated and compassionate leader. 
                    Known for his inspiring sermons, he guides his congregation with wisdom and integrity. With a heart for service, 
                    he uplifts and supports others, making a lasting impact on their spiritual journey.
                    </motion.p>
                    <ul>
                        {
                            listItems.map((item, index)=>(
                                <motion.li key={index}
                                ref={ref}
                                initial="hidden"
                                animate={controls}
                                variants={variants}
                                transition={{ duration: 1.2, delay: index }}
                                className='flex items-center gap-3 font-normal text-sm'>
                                    <span><FaCheckCircle className='text-choco'/></span>{item}
                                </motion.li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className='h-1/2 md:h-full md:w-1/2'>
                <img src={pastorAndWife} alt="" className='h-full object-cover'/>
            </div>
        </div>
    </div>
  )
}

export default AboutPastor