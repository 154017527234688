
import { PageRoutes } from './routes';
import { MobileNavigationContextProvider } from './contexts/mobileNavigationContext';

function App() {
 

  return (
    <MobileNavigationContextProvider>
      <PageRoutes />
    </MobileNavigationContextProvider>
  )
}

export default App
