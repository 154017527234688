import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LandingPage, About, Contact, Ministries, Giving } from '../pages';
import { FooterSection } from '../components/sections/footer-section';
import { DesktopNavigation, MobileNavigation } from '../components/header';



const PageRoutes = () => {

  

  return (
    <>
      <ToastContainer />
    <Router>
      {/* <div className='w-full'>
          <DesktopNavigation />
      </div> */}
        <Routes>
            <Route path='/' element={<LandingPage/>} />
            <Route path='/about-us' element={<About/>} />
            <Route path='/contact-us' element={<Contact/>} />
            <Route path='/ministries' element={<Ministries/>} />
            <Route path='/givings' element={<Giving/>} />
        </Routes>
        <FooterSection />
    </Router>
    </>
  )
}

export default PageRoutes