import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CiMenuBurger, CiMenuFries } from "react-icons/ci";

import logo from '../../assets/svg/logo_image.svg'

const MobileNavigationHeader = ({ handleToggleShowNavitems }) => {
  const navigateTo = useNavigate();
  return (
    <div className='flex items-center justify-between px-5 mt-0 md:hidden bg-white'>
        <img 
        onClick={()=>navigateTo('/')}
        src={logo} alt="seventh day adventist chirch liberty chapel" 
        className='w-12 h-12 border border-white rounded-full cursor-pointer' />
        <span className='' onClick={handleToggleShowNavitems}><CiMenuBurger className='w-6 h-6 text-yellow font-xbold' /></span>
    </div>
  )
}

export default MobileNavigationHeader