import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { FaGifts } from "react-icons/fa6";
import { MdContactSupport } from "react-icons/md";
import logo from '../../assets/svg/logo_image.svg'

const DesktopNavigation = () => {
    const navigateTo = useNavigate();
    const navItems = [
        // {
        //     name:'Home',
        //     url:'/',
        //     icon:<FaHome />
        // },
        {
            name:'About Us',
            url:'/about-us',
            icon:<TbListDetails />
        },
        // {
        //     name:'Giving',
        //     url:'/givings',
        //     icon:<FaGifts />
        // },
        {
            name:'Contact Us',
            url:'/contact-us',
            icon:<MdContactSupport />
        },
    ]

    
  return (
    <div className='hidden w-11/12 m-auto  md:flex items-center justify-between bg-white font-primary py-3 px-5'>
        <img src={logo} 
        onClick={()=>navigateTo('/')}
        className='w-12 h-12 border border-white rounded-full cursor-pointer' alt="seventh day adventist chirch liberty chapel" />
        <ul className='flex items-center'>
            {
                navItems && navItems.map((item, index)=>(
                    <li 
                    onClick={()=>navigateTo(item.url)}
                    key={index}
                    className='flex items-center gap-1 text-blue text-xsm cursor-pointer hover:text-yellow 
                    ease-in duration-500 font-medium'><span className='ml-5'>{item.icon}</span>{item.name}</li>
                ))
            }
        </ul>
    </div>
  )
}

export default DesktopNavigation