import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ContactForm } from '../../forms';


const ContactSection = () => {

    const controls = useAnimation();
    const { ref, inView } = useInView();

    React.useEffect(() => {
        if (inView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [controls, inView]);

      const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
        }
  return (
    <div className='h-screen flex flex-col justify-center bg-church bg-cover bg-no-repeat bg-center relative'>
        <div className='flex items-center h-full w-full bg-darkBlue/90'>
            <div className='md:w-10/12 m-auto flex flex-col md:flex-row items-center gap-10 px-2 md:px-0 font-primary'>
                <div className="md:w-5/12 hidden md:block">
                    <span className='text-white'>___________</span>
                    <motion.h4 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.2 }}
                    className='text-white text-lg font-subPrimary'>send a message?</motion.h4>
                    <motion.h2 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.4 }}
                    className='text-lg text-whiteSmoke md:text-xl font-xbold leading-[2.6rem] md:leading-[4rem] capitalize my-2'>
                        got a question</motion.h2>
                    <motion.p 
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        transition={{ duration: 1.2, delay: 0.6 }}
                    className='py-3 text-whiteSmoke text-sm'>
                    If you are a reporter looking for information on media services please contact us at: <br />
                    <span className='font-bold'>libertychapel@gmail.com</span>
                    </motion.p>
                </div>
                <div className='md:w-7/12'>
                    <ContactForm />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactSection