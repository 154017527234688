import React from 'react';


const LowerFooterSection = () => {
  return (
    <div className='bg-footer/95 text-xsm font-primary flex flex-col md:flex-row md:items-center justify-around py-3 px-4 md:px-0'>
        <h1 className='text-white'>
          Created With Love By 
               <a
            className='ml-2 text-yellow underline' 
            href='https://www.linktree.com/igah.franklin'
            target='_blank' 
            rel='noopener noreferrer' 
          >
            Igah Franklin
          </a>
          </h1>
        <h1 className='text-white'>&copy; liberty Chapel <br className='block md:hidden' /> Seventh Day Adventist Church, 2024</h1>
    </div>
  )
}

export default LowerFooterSection