import React from 'react';
import { Socials } from '../../social';
import TopFooter from './TopFooter';
import LowerFooterSection from './LowerFooterSection';
import logo from '../../../assets/svg/logo_image.svg'

const FooterSection = () => {
    const navItems = [
        {
            name:'Home',
            url:'/',
        },
        {
            name:'About Us',
            url:'/about-us',
        },
        // {
        //     name:'Givings',
        //     url:'/givings',
        // },
        {
            name:'Contact Us',
            url:'/contact-us',
        },
    ]
  return (
    <>
        <TopFooter/>
        <div className='bg-footer'>
            <div className='flex flex-col md:flex-row  md:justify-around gap-10 md:gap-3 p-10 font-primary'>
                <div>
                    <img src={logo} alt="seventh day adventist chirch liberty chapel" className='w-12 h-12 border border-white rounded-full' />
                </div>
                <div>
                    <h3 className='border-b text-whiteSmoke text-normal mb-5'>Menu</h3>
                    <ul>
                        { 
                            navItems && navItems.map((item, index)=>(
                                <li key={index} className='text-whiteSmoke font-normal text-sm mt-1 cursor-pointer hover:text-yellow ease-in duration-500'>{item.name}</li>
                            ))
                        }
                    </ul>
                </div>
                <div>
                <h3 className=' text-whiteSmoke text-normal mb-5'>Contact Us</h3>
                <p className='text-white'>2 Wennike Briggs Close, Utako. FCT Abuja</p>
                <p className='text-white'>07061091667</p>
                <p className='text-white'>Libertychapelsda@gmail.com</p>
                </div>
                <div>
                <h3 className=' text-whiteSmoke text-normal mb-5'>Get Connected</h3>
                <Socials />
                </div>
            </div>
        </div>
        <LowerFooterSection />
    </>
  )
}

export default FooterSection